html,
body,
#root {
  background-color: black;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// ---------------- BREAKPOINTS ----------------

$breakpoint-xs: 320px;
$breakpoint-s: 480px;
$breakpoint-m: 768px;
$breakpoint-l: 960px;
$breakpoint-xl: 1100px;
$breakpoint-xxl: 1400px;

// --------------- SIZE -----------------------
$xxl: 32px;

$m: 12px;
$s: 8px;
$xs: 4px;

// --------------- FONTS -----------------------



@font-face {
  font-family: Neue Haas Grotesk Text Pro;
  src: url('./fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}

@font-face {
  font-family: JKR Haas Grotesk;
  src: url("./fonts/JKR-Haas-Grotesk-Ritchie.otf") format("opentype");
}
@font-face {
  font-family: JKR Haas Grotesk Bold;
  src: url("./fonts/JKR-Haas-Grotesk-Knowles.otf") format("opentype");
}

@font-face {
  font-family: Digital-7 Mono;
  src: url("./fonts/digital-7-mono.ttf") format("truetype");
}

@font-face {
  font-family: Gobold;
  src: url("./fonts/Gobold-Regular.woff");
}

@font-face {
  font-family: Gobold Bold;
  src: url("./fonts/Gobold-Bold.woff");
}

@font-face {
  font-family: Sequel100Black-85;
  src: url("./fonts/Sequel100Black-85.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto+Mono:wght@400;700&display=swap");

// --------------- COLORS -----------------------

$ticketdex-gradient: linear-gradient(
  60deg,
  rgb(184, 144, 255) 0%,
  rgb(120, 208, 255) 97%,
  rgb(117, 212, 255) 100%
);

// grayscale
$white: #fafafa;
$gray-secondary: #8d8d8d;
$ticketdex-purple: #e18df4;
$ticketdex-green: #43ba7d;

$gray-400: #bdbdbd;

// xp colors

$primary-navy: #18056c;
$primary-beige: #ffe9d2;

$secondary-blue: #19b2e6;
$secondary-blue-30: rgba(25, 178, 230, 0.3);
$secondary-gray-white: #e5e6ff;
$secondary-pink: #db4b81;

$neutral-black: #181a1e;
$white: #fafafa;
$neutral-gray: #b8b8b8;
$neutral-dark-gray: #3b3d41;
$neutral-input-box: #292929;
$ticketdex-non-opaque-background: #1d1b22;

$neutral-white-alpha-50: rgba(250, 250, 250, 0.5);
$neutral-white-alpha-30: rgba(250, 250, 250, 0.3);

$neutral-black-alpha-50: rgba(24, 26, 30, 0.5);
$neutral-black-alpha-30: rgba(24, 26, 30, 0.3);

$system-green-green: #19b963;
$system-yellow-yellow: #f2da04;
$system-red-red: #d41503;

$system-green-alpha-50: rgba(25, 185, 99, 0.5);
$system-green-alpha-30: rgba(25, 185, 99, 0.3);

$system-yellow-alpha-50: rgba(242, 218, 4, 0.5);
$system-yellow-alpha-30: rgba(242, 218, 4, 0.3);

$system-red-alpha-50: rgba(212, 21, 3, 0.5);
$system-red-alpha-30: rgba(212, 21, 3, 0.3);

$glass-background: linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.01) 100%
  ),
  rgba(0, 0, 0, 0.25);

$glass-stroke: linear-gradient(
  137deg,
  rgba(255, 255, 255, 0.25) 0%,
  rgba(255, 255, 255, 0.01) 100%
);

$glass-stroke-alt: radial-gradient(
    170.42% 50% at 50% 50%,
    #fff 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  radial-gradient(
    198.89% 50% at 50% 50%,
    #0a7abf 0%,
    rgba(255, 255, 255, 0.7) 100%
  ),
  radial-gradient(0% 50% at 50% 50%, #0f0f0f 0%, rgba(21, 21, 21, 0) 100%);

$glass-disabled: linear-gradient(
    137deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.01) 100%
  ),
  rgba(75, 75, 75, 0.75);

$glass-hologram: url(https://s3-alpha-sig.figma.com/img/e7a0/4d7e/b4024f01adca241cd391651b1a868550?Expires=1688947200&Signature=SOoQHh4Cz8dPvST7Yp8v0u2x70otgSbBcUVEIEpP3FEUox3QOs92tLDbsoEuFjk9R2NidfVD7y5O6ag7smOMkiX4m4phMGB1ErlII~x6821QVHLNQM0la1P6w9YmeXhdivE8SL6pWtSfYxfzSuARNM6KHFBpJ5vccbjwM7wq7R5KCru--WOtZflAmwY6epEhss8oHZPZaB0~M243S~ltHmkAHJ7dK5QGJ8gdOqlu3AZQYGyGnp4R6HG00UkcKpSbb1O7R0nKCWWuose8yIopuSqgLFuqAtaJ0tnMjhMm-di6kn5nZsEvS6Gxaw17uXqQJAK0fOu3bz38W~SnHRF0MA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4);
// --------------- MIXINS -----------------------

// ---- display ----

@mixin display-xl {
  font-size: 88px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin display-l {
  font-size: 80px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin display-m {
  font-size: 72px;
  font-family: JKR Haas Grotesk Bold;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin display-s {
  font-size: 61px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin header-xl {
  font-size: 49px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin header-l {
  font-size: 39px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin header-m {
  font-size: 32px;
  font-family: JKR Haas Grotesk Bold;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin header-s {
  font-size: 25px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin header-xs {
  font-size: 20px;
  font-family: Sequel100Black-85;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@mixin body-xl-bold {
  font-size: 25px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-l-bold {
  font-size: 20px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-m-bold {
  font-size: 16px;
  font-family: JKR Haas Grotesk Bold;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-s-bold {
  font-size: 13px;
  font-family: JKR Haas Grotesk Bold;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-xs-bold {
  font-size: 10px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin body-xl {
  font-size: 24px;
  font-family: Neue Haas Grotesk Text Pro;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-l {
  font-size: 20px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-m {
  font-size: 16px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-s {
  font-size: 13px;
  font-family: Neue Haas Grotesk Text Pro;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin body-xs {
  font-size: 10px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

// --------------- borders ----------------------

@mixin stroke-border {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 1px solid transparent;
    background: $glass-stroke border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 1;
    transition: 0.3s all;
    pointer-events: none;
  }
}

@mixin glass-background {
  border-radius: 8px;
  background: var(
    --glass-background,
    linear-gradient(
      137deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.01) 100%
    ),
    rgba(0, 0, 0, 0.25)
  );
  /* Glass/Glass + Shadow */
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
}

// --------------- GLOBAL -----------------------
.landing-container-center-content .wallet-adapter-button,
.landing-container-center-content .wallet-adapter-button-trigger {
  all: unset !important;
}

.landing-connect-button-container .wallet-adapter-button-start-icon {
  display: none !important;
}

.header-page {
  width: 100%;
  height: 100%;
}

.header-page-black {
  width: fit-content;
  min-width: 100%;
  min-height: 100%;
  height: fit-content;
  background: #0a0909;
}

.header-page-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  color: $ticketdex-green;
}
