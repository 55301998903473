@import "../css/global.scss";

.contentContainer {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
    width: 100%;
  }

  :global(.wallet-adapter-dropdown){
    width:100% !important;
  }

  :global(.wallet-adapter-button-trigger) {
    background-color: #19b963 !important;
    box-shadow: 0px 0px 64px 8px #07a135;
    border: unset;
    color: #181a1e;
    font-family: JKR Haas Grotesk Bold !important;
    box-sizing:border-box;
    height:67px !important;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-items: center;
    background: transparent;
    padding: 1.5rem 2rem !important;
    text-align: center;
    transition: 0.3s all;
    cursor:pointer;
  }

}

.contentRight {
  color: $white;
  font-family: JKR Haas Grotesk Bold;
  font-style: normal;
  font-weight: 400;
  width: calc(55% - 0.5rem);

  @media (max-width: 960px) {
    width: 100%;
  }

  h1 {
    font-size: 2rem;
    margin: 0;
    margin-top: 1rem;
  }

  p {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.contentHeader {
  display: flex;
  justify-content: space-between;
}

.stepContainer {
  align-items: center;
  border-radius: 0.25rem;
  background-color: $neutral-black-alpha-50;
  display: flex;
  text-shadow: 0px 0px 50px 0px rgba(133, 133, 133, 0.50);
  font-family: JKR Haas Grotesk Bold;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.stepDisabled {
  border-radius: 0.25rem;
  background-color: $neutral-white-alpha-30;
  width: 4.15rem;
  height: 0.25rem;
}

.stepActive {
  border-radius: 0.25rem;
  background-color: $white;
  width: 4.15rem;
  height: 0.25rem;

  &:first-of-type {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

.contentLeft {
  margin-right: 1rem;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.image {
  border: 1px solid $neutral-white-alpha-30;
  border-radius: 0.625rem;
  width: 26rem;
  height: 26rem;
  object-fit: cover;

  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}

.button {
  margin-top: 1rem;
}

.unsealLoading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $white;

  h1 {
    color: $white;
    font-family: JKR Haas Grotesk Bold;
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
    text-align: center;
  }

  p {
    color: $neutral-gray;
    font-family: JKR Haas Grotesk;
    font-size: 0.8125rem;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
  }

  img {
    border-radius: 0.5rem;
    width: 20.625rem;
    height: 20.625rem;
    margin: 1rem auto 2rem auto;

    @media (max-width: 960px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
      height: auto;
    }
  }
}

.tokenDetails {
  border: 1px solid $neutral-white-alpha-30;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem;

}

.tokenDetailSmall {
  border-radius: 0.5rem;
  border: 1px solid $neutral-white-alpha-30;
  padding: 1rem;
  margin-bottom: 1rem;
  width: calc(50% - 2.5rem);
}

.tokenDetail {
  border: 1px solid $neutral-white-alpha-30;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
}

.detailLabel {
  color: $neutral-gray;
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 0.9375rem;
  font-weight: 500;
}

.detailValue {
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 1rem;
}

.metadata {
  color: #E7E5E1;
  display: flex;
  justify-content: space-between;
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 0.9375rem;
  margin-top: 1rem;

  span {
    color: $neutral-gray;
  }

  svg {
    path {
      fill: $white;
    }
  }
}

.solanaLinks {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.secretReveal {
  color: $system-green-green;
  display: inline-block;
  margin-top: 0.5rem;
}

.alert {
  background-color: $system-yellow-alpha-30;
  border-radius: 0.625rem;
  color: $system-yellow-yellow;
  margin-top: 1rem;
  padding: 1rem;
  text-align: center;
}

.unsealingModalContainer {
  border-radius: 1.25rem;
  border: 1px solid $neutral-white-alpha-30;
  background: $neutral-black;
  padding: 1rem;

  @media (max-width: 960px) {
    height: calc(100vh - 4rem);
  }

  button {
    margin-bottom: 1rem;
  }
}

.copyButtonContainer{
  display:flex;
  align-items:center;
  justify-content:center;
  padding: 8px 24px 8px 24px;
  border-radius: 24px;
  border: 1px solid white;
  cursor:pointer;
  gap: 8px;
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
}
