@import "../css/global.scss";

.container {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  padding: 1rem;

  @include stroke-border;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.subtext {
  color: $neutral-gray;
  font-family: JKR Haas Grotesk;
  font-size: 0.8125rem;
  margin-bottom: 1rem;
  text-align: center;
}

.icon {
  font-size: 40px;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
}

.header {
  color: $system-red-red;
  font-family: JKR Haas Grotesk Bold;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.image {
  border-radius: 0.625rem;
  border: 1px solid $neutral-input-box;
  width: 15.5rem;
  height: 15.5rem;
  object-fit: cover;
  margin: 1rem auto 1rem auto;
}

.infoContainer {
  color: $white;
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
}

.listItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    width: 100%;
  }
}

.link {
  color: $white;
  cursor: pointer;
  font-family: JKR Haas Grotesk;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  text-align: center;

}