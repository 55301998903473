@import "../../css/global.scss";

.headerRight {
  display: flex;
  align-items: center;

  :global(.wallet-adapter-button-trigger) {
    background-color: transparent !important;
    border-radius: 8px;
    border: 1px solid $white;
    color: $white;
    cursor: pointer;
    font-family: Neue Haas Grotesk Text Pro !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    height: 41px;
    position: relative;
    z-index: 100;



    @media (max-width: 768px) {
      min-width: unset;
      padding: 0.5rem 1rem;
      svg {
        margin-right: 0;
      }
    }
  }
}


.blackHeader {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 960px) {
    background: $glass-background;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
  }
}

.headerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
}

.headerLink {
  align-items: center;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  color: $secondary-gray-white;
  cursor: pointer;
  display: flex;
  @include body-s-bold;
  height: 43px;
  margin-right: 1.5rem;
  padding: 0.5rem;
  text-decoration: none;
  transition: 0.3s all;
  z-index: 1;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 0.25rem;

    path {
      fill: #f5f6f6;
    }
  }

  &:hover {
    color: $secondary-gray-white;
    border-bottom: 2px solid $secondary-gray-white;
  }

  @media (max-width: 960px) {
    display: none;
  }
}

.headerLogo {
  z-index: 1;

  @media (max-width: 960px) {
    width:40%;
  }

  @media (max-width: 500px) {
    width:25%;
  }

}

.selected,
.selected:hover {
  color: $secondary-gray-white;
  border: unset;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: $secondary-gray-white;
    bottom: 0px;
    left: 0;
  }
}
