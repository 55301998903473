@import "../../css/global.scss";

.nftListItem {
  background: rgba(61, 61, 61, 0.25);
  backdrop-filter: blur(12.5px);
  border-radius: 1.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  width: 19.5rem;

  @include stroke-border;

  &:hover {
    &:before {
      border: 2px solid transparent;
      background: $neutral-gray;
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
}

.nftImage {
  border: 1px solid #292929;
  border-radius: 4px;
  
  object-fit: cover;
  width: 19.5rem;
  height: 19.5rem;

}



.nftDate {
  color: $secondary-blue;
  @include body-s-bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.nftTitle {
  color: $primary-beige;
  @include body-l-bold;
  margin-bottom: 0.5rem;
}

.nftVenue {
  align-items: center;
  color: $secondary-gray-white;
  display: flex;
  @include body-s;
  svg {
    margin-right: 0.5rem;
    path {
      fill: $secondary-gray-white;
    }
  }
}

.ticketQuantity {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: fit-content;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  svg {
    margin-right: 0.5rem;
  }
}

.nftStatus {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  padding: 1rem;

  h1 {
    color: $white;
    font-family: JKR Haas Grotesk Bold;
    font-size: 1.25rem;
  }

  p {
    color: $neutral-gray;
    font-family: Neue Haas Grotesk Text Pro;
    font-size: 0.875rem;
    margin: 0;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
