@import "../../css/global.scss";

.footerContainer {
  bottom: 0;
  color: $white;
  @include body-s;
  text-align: center;
  position: fixed;
  padding: 1rem 0;
  width: 100%;

  @media (max-width: 960px) {
    position: relative;
  }
}

