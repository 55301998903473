.pageContainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/tensor/grunge_bg+1.png);
}

.contentContainer {
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 1rem;
  position: relative;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 1rem;
  }
}

.bgImage {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: top;
  position: fixed;
  z-index: 0;
}
