@import "../../css/global.scss";

.button {
  @include body-m-bold;

  align-items: center;
  background: transparent;
  border: 1px solid $white;
  border-radius: 12px;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1.5rem 2rem;
  text-align: center;
  transition: 0.3s all;
  width: fit-content;

  position: relative;
  z-index: 1;

  &:hover {
    &::before {
      border: unset;
    }
  }

  &:disabled {
    background-color: $neutral-dark-gray;
    color: $neutral-gray;
    box-shadow: unset;
    cursor: not-allowed;
  }
}


.fullWidth {
  display: flex;
  justify-content: center;
  width: 100%;
}

.compact {
  align-items: center;
  display: flex;
  font-size: 16px;
  height: fit-content;
  padding: 0.5rem 1rem;
  width: fit-content;
}

.leftIcon {
  align-items: center;
  display: flex;
  margin-right: 0.5rem;
}

.rightIcon {
  align-items: center;
  display: flex;
  margin-left: 0.5rem;
}


.outline {
  background-color: transparent;
  border: 1px solid $white;
}

.rounded {
  border-radius: 40px;

  &::before {
    border-radius: 40px !important;
  }
}

.green {
  background-color: $system-green-green;
  box-shadow: 0px 0px 64px 8px #07A135;
  border: unset;
  color: $neutral-black;
  font-family: JKR Haas Grotesk Bold;
  font-size: 1rem;
}