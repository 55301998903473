@import "../../css/global.scss";

.tag {
  @include body-s-bold;
  align-items: center;
  background: $white;
  border-radius: 4px;
  background-size: cover;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: fit-content;

  svg {
    margin-right: 0.5rem;
  }
}

.sealedTag {
  background-color: $system-green-alpha-30;
  color: $system-green-green;
}

.readyToClaimTag {
  background-color: $system-yellow-alpha-30;
  color: $system-yellow-yellow;
}

.claimedTag {
  background: $system-yellow-alpha-30;
  color: $system-yellow-yellow;
}

.neutralTag {
  background-color: $neutral-white-alpha-30;
  color: $white;
}

.badTag {
  background-color: $system-red-alpha-30;
  color: $system-red-red;
}
