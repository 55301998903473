@import "../css/global.scss";

.pageContainer {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  text-align: center;
  h1 {
    color: $white;
    font-family: JKR Haas Grotesk Bold;
    font-size: 2rem;
    margin-bottom: 1rem;


  }

  p {
    @include body-s-bold;
    color: $neutral-gray;
    margin-bottom: 1.5rem;
  }
}

.filterButtonContainer {
  display: flex;
  margin-bottom: 2rem;

  button {
    align-items: center;
    display: flex;
    width: fit-content;
    margin-right: 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.noUpcoming {
  align-items: center;
  border-radius: 8px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2rem auto;
  padding: 8rem 1rem;
  width: 50%;

  @media (max-width: 500px) {
    width: 80%;
  }


  p {
    color: $neutral-gray;
    font-family: JKR Haas Grotesk Bold;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;

    a {
      color: $neutral-gray;
    }
  }

  h2 {
    color: $white;
    font-family: JKR Haas Grotesk Bold;
    font-size: 2rem;
  }

  .linkButton {
    width: 100%;
  }

  button {
    margin-top: 2rem;
    width: 100%;
    justify-content: space-between;
    img {
      width: 1.5rem;
      height: 1.25rem;
    }
  }
}

.iconContainer {
  align-items: center;
  @include glass-background;
  @include stroke-border;
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  width: 64px;
}

.nftContainer {
  margin: 0 auto;
  width: 1100px;
  min-height: calc(100vh + 5rem);

  @media (max-width: 960px) {
    width: unset;
  }
}

.nftListContainer {
  background-image: url(https://hngr-icons.s3.amazonaws.com/supperclub/tensor/gridbackground.png);
  background-size: contain;
  border-radius: 1.25rem;
  border: 1px solid $neutral-input-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 5rem;
  padding: 1rem;
}

.loadingSubtext {
  @include body-s;
  text-align: center;
  color: $white;
}

.loadingContainer {
  color: $white;
  text-align: center;
  padding: 10rem 10rem 20rem 10rem;

  @media (max-width: 960px) {
    padding: 15rem 0;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
}
