@import "../css/global.scss";

.unsealModalContainer {
  background: $ticketdex-non-opaque-background;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @include stroke-border;

  @media (max-width: 960px) {
    height: calc(100vh - 4rem);
  }
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.warningIcon {
  font-size: 40px;
  text-align: left;
  margin: 1rem auto 0 auto;
  text-align: center;
  width: 100%;
}

.unsealWarning {
  color: $system-yellow-yellow;
  font-family: JKR Haas Grotesk Bold;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  margin: 0 auto 1rem auto;
  width: 80%;

  @media (max-width: 960px) {
    font-size: 1.5rem;
  }
}

.unsealed {
  color: $system-green-green;
  margin-top: 2rem;
}

.subtext {
  color: $neutral-gray;
  font-family: JKR Haas Grotesk;
  font-size: 0.8125rem;
  margin-bottom: 1rem;
  text-align: center;
}

.warningListContainer {
  background: $neutral-input-box;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: calc(100% - 2rem);

  @include stroke-border;
}

.warningListItem {
  align-items: center;
  color: $white;
  display: flex;
  @include body-s;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  width: 100%;

  button, div {
    width: fit-content;
  }

  div {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 960px) {
    flex-direction: column-reverse;

    button {
      margin-bottom: 1rem;
    }
  }
}

.image {
  border-radius: 0.625rem;
  border: 1px solid $neutral-input-box;
  width: 15.5rem;
  height: 15.5rem;
  object-fit: cover;
  margin: 1rem auto 1rem auto;

  @media (max-width: 960px) {
    width: 12rem;
    height: 12rem;
    margin-top: 0;
  }
}

.link {
  color: $white;
  cursor: pointer;
  font-family: JKR Haas Grotesk;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  text-align: center;

}

.imageContainer {
  margin: 0 auto;
  position: relative;
  width: fit-content;
}

.unsealedTag {
  white-space: nowrap;
  border-radius: 0.25rem;
  background: $system-green-green;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(12.5px);
  font-family: JKR Haas Grotesk Bold;
  font-size: 1rem;
  left: calc((100% - 128px) / 2);
  padding: 0.5rem;
  position: absolute;
  top: calc((100% - 35px) / 2);
  
}
