.modalContainer {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  margin: 5rem auto;
  height: fit-content;
  width: 392px;

  @media (max-width: 480px) {
    margin-top: 1rem;
    width: calc(100% - 2rem);
    height: calc(100vh - 4rem);
  }
}

.medium {
  width: 37.5rem;

  @media (max-width: 480px) {
    width: calc(100% - 2rem);
  }
}
