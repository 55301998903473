@import "../css//global.scss";

.homeContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;

  @media (max-width: 960px) {
    margin-top: 1rem;
  }

  h1 {
    color: $white;
    @include display-m;
    margin: 0;
    margin-top: 2rem;

    @media (max-width: 960px) {
      font-size: 48px;
      text-align: center;
    }
  }

  :global(.wallet-adapter-button) {
    background-color: unset !important;
    border: 1px solid white !important;
    font-family: JKR Haas Grotesk Bold !important;
    font-size: 1rem !important;
    padding: 1rem 3rem !important;
  }
}

.homeText {
  color: $neutral-gray;
  @include body-xl;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media (max-width: 960px) {
    text-align: center;
  }
}

